angular.module('mx.filters', [])

.filter('humanize', function  () {
	return function (s){
		s = ( s === undefined || s === null ) ? '' : s;
		s = s.replace(/(-|_)/g, ' ')		
    return s.toString().toLowerCase().replace( /\b([a-z])/g, function(ch) {
        return ch.toUpperCase();
    });
	}
})

.filter('titlecase', function() {
    return function(s) {
        s = ( s === undefined || s === null ) ? '' : s;
        return s.toString().toLowerCase().replace( /\b([a-z])/g, function(ch) {
            return ch.toUpperCase();
        });
    };
})



.filter('slug', function  () {
	return function ( input ){
		if (!input) return;
		var slug = input.toLowerCase().trim();
		slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
		slug = slug.replace(/[\s-]+/g, '-');
		return slug;
	}
})




.filter("replace", [ function(){
 return function(str, pattern, replacement, global){
    global = (typeof global == 'undefined' ? true : global);
    try {
	  str = str ? (typeof global == 'string' ? str : str.toString()) : '';
      return str.replace(new RegExp(pattern,global ? "g": ""),function(match, group) {
        return replacement;
      });  
    } catch(e) {
      console.error("error in string.replace", e);
      return (str || '');
    }     
  } 
 }                
])


.filter("replaceAmp", [ function(){
 return function(str){
	 if( typeof( str ) !== 'undefined' && str !== null  ) str =  str.replace( '&amp;', '&')
	 return str;
  } 
 }                
])



.filter('truncate', function () {
    return function (value, wordwise, max, tail) {
        if (!value) return '';
        max = parseInt(max, 10);
        if (!max) return value;
        if (value.length <= max) return value;

        value = value.substr(0, max);
        if (wordwise) {
            var lastspace = value.lastIndexOf(' ');
            if (lastspace != -1) {
                value = value.substr(0, lastspace);
            }
        }

        return value + (tail || ' ...');
    };
})

	.filter('htmlToPlaintextTruncate', function() {
	  return function(text, length, end) {
	    var htmlToPlaintext;
	    if (isNaN(length))
	        length = 10;
	 
	    if (end === undefined)
	        end = "...";
	 
	    if (text.length <= length || text.length - end.length <= length) {
	      return String(text).replace(/<[^>]+>/gm, '');
	    }
	    else {
	      htmlToPlaintext = String(text).replace(/<[^>]+>/gm, '');
	        return String(htmlToPlaintext).substring(0, length-end.length) + end;
	    }
	 
	  }
	})




.filter('tel', function () {
    return function (tel) {
        if (!tel) { return ''; }

        var value = tel.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number;

        switch (value.length) {
            case 7: // +1PPP####### -> C (PPP) ###-####
            		country = 1;
            		city = 1;
                number = value
                break;

            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 3);
                city = value.slice(3, 5);
                number = value.slice(5);
                break;

            default:
                return tel;
        }

        if (country == 1) country = "";
        if (city == 1) city = "";
				else city = " (" + city + ") "
        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + city  + number).trim();
    };
})
.filter('trust', [
  '$sce',
  function($sce) {
    return function(value, type) {
      // Defaults to treating trusted text as `html`
      return $sce.trustAs(type || 'html', value);
    }
  }
])



.filter('trunc_characters', function () {
      return function (input, chars, breakOnWord) {
          if (isNaN(chars)) return input;
          if (chars <= 0) return '';
          if (input && input.length > chars) {
              input = input.substring(0, chars);

              if (!breakOnWord) {
                  var lastspace = input.lastIndexOf(' ');
                  //get last space
                  if (lastspace !== -1) {
                      input = input.substr(0, lastspace);
                  }
              }else{
                  while(input.charAt(input.length-1) === ' '){
                      input = input.substr(0, input.length -1);
                  }
              }
              return input + '...';
          }
          return input;
      };
  })
  .filter('trunc_splitcharacters', function() {
      return function (input, chars) {
          if (isNaN(chars)) return input;
          if (chars <= 0) return '';
          if (input && input.length > chars) {
              var prefix = input.substring(0, chars/2);
              var postfix = input.substring(input.length-chars/2, input.length);
              return prefix + '...' + postfix;
          }
          return input;
      };
  })
  .filter('trunc_words', function () {
      return function (input, words) {
          if (isNaN(words)) return input;
          if (words <= 0) return '';
          if (input) {
              var inputWords = input.split(/\s+/);
              if (inputWords.length > words) {
                  input = inputWords.slice(0, words).join(' ') + '...';
              }
          }
          return input;
      };
  })

	
	.filter('ceil', function() {
	    return function(n) {
		    return Math.ceil( n  )
	    };
	})
	


	.filter( 'zPad', function() {
		return 	function zeroPad( num, places ) {
		  var zero = places - num.toString().length + 1;
		  return Array(+(zero > 0 && zero)).join("0") + num;
		}
	})
	
		.filter( 'toDate', function() {
		return 	function zeroPad( date ) {
			if( typeof( date ) != 'undefined' ) date =  new Date( date );
			return date;
		}
	})


	
	.filter('dateToISO', function() {
	  return function(input) {
	    input = new Date(input).toISOString();
	    return input;
	  };
	})




; // end of filters 