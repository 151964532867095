

/**
 * in_array function.
 * 'a[href*=#]:not([href=#])'
 * @access public
 * @param mixed needle
 * @param mixed haystack
 * @param mixed argStrict
 * @return void
 */
function in_array(needle, haystack, argStrict) {

    var key = '',
      strict = !! argStrict;
  
    //we prevent the double check (strict && arr[key] === ndl) || (!strict && arr[key] == ndl)
    //in just one for, in order to improve the performance 
    //deciding wich type of comparation will do before walk array
    if (strict) {
      for (key in haystack) {
        if (haystack[key] === needle) {
          return true;
        }
      }
    } else {
      for (key in haystack) {
        if (haystack[key] == needle) {
          return true;
        }
      }
    }
  
    return false;
  }
  