/* Polyfill service v3.25.1
 * For detailed credits and licence information see https://github.com/financial-times/polyfill-service.
 * 
 * UA detected: ie/11.0.0
 * Features requested: Array.prototype.includes
 * 
 * - Array.prototype.includes, License: CC0 */

(function(undefined) {

    // Array.prototype.includes
    (function () {
        Object.defineProperty(Array.prototype, 'includes', {
            configurable: true,
            value: function includes (searchElement /*, fromIndex*/) {
                'use strict';
                var O = Object(this);
                var len = parseInt(O.length) || 0;
                if (len === 0) {
                    return false;
                }
                var n = parseInt(arguments[1]) || 0;
                var k;
                if (n >= 0) {
                    k = n;
                } else {
                    k = len + n;
                    if (k < 0) {
                        k = 0;
                    }
                }
                var currentElement;
                while (k < len) {
                    currentElement = O[k];
                    if (searchElement === currentElement ||
                        (searchElement !== searchElement && currentElement !== currentElement)) {
                        return true;
                    }
                    k++;
                }
                return false;
            },
            writable: true
        });
    }());
    })
    .call('object' === typeof window && window || 'object' === typeof self && self || 'object' === typeof global && global || {});