

	/**
	 * Javascript window.open 
	 * 
	 ** Used for items that should link , but aren?t anchors
	 ** Looks at data for href and target. 
	 * 
	 * 
	 * @access public
	 * @return void
	 */
	function clickable( options ){
		var option_pressed = false;
		$(document).on({
	   keydown: function(e) {
        if(e.which == 91) option_pressed = true;
	    },
	    keyup: function(e) {
	    	option_pressed = false;
	    }
	  });
		// add click functionality to non anchors
		$('[data-click-href]').each(function(){
			$(this).css('cursor', 'pointer');
			$(this).on({
				mouseup : function( e ) {
					e.preventDefault();
					var $target = $(this).data('click-target') || '_self';
					if( option_pressed ) $target = "_blank";
					window.open($(this).data('click-href'),  $target);
				}
			})
		})
	}
	