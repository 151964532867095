

	//--------------------- SMOOTH SCROLL
	function smooth_scroll( options  ){
		var f_options = {};
		var default_f_options = { 
			targetEl : 'a[href^="#"], .scroller a[href]',
			offset : 0 ,
			offsetEl : false 
		};
		if( typeof( options ) !== 'undefined' && typeof( options.smooth_scroll ) !== 'undefined' ){
			f_options =$.extend(  default_f_options  , options.smooth_scroll);
		}else{
			f_options = default_f_options;
		}
	if( typeof( f_options.enabled ) !== 'undefined' && f_options.enabled == false ) return 
    if( f_options.offsetEl ){
      var header = $( f_options.offsetEl );
			if( header.length > 0 ) f_options.offset = header.height()
    }
    $( f_options.targetEl ).click(function(e) {
	    if ( !$(this).attr('data-toggle') ){ 
    		e.preventDefault();
		    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
		      var target = $(this.hash);
		      target = target.length ? target : $('[name="' + this.hash.slice(1) +'"]');
		      if (target.length) {
				    $('html,body').animate({    
						scrollTop: target.offset().top - f_options.offset + 'px'
		        	}, 1000);
		        return false;
		      } 
		    }
	    }
		});				
	}
		
    /** Legacy // Probably Deprecated */
    if( typeof( 'mx_scrollTo') !== 'function') {
        function mx_scrollTo( $anchor , $offset){
            var $offset = $offset || 0;
          $('html,body').animate({
            scrollTop: $($anchor).offset().top + $offset
          }, 1000);
        }
    }
    
    