	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	angular.module('mx.directives', [])
	// -------------------------------------------------------
	// Make things that aren't links look and be  clickable
	// -------------------------------------------------------
	.directive('mxClickable', [function(){
		return {
	    require: '?ngModel',
			link : function($scope, element , attrs){
				$(element).addClass('mx-clickable');
				$(element).on('click', function(){
					window.location.href = attrs.mxClickable
				})
			}
		}
	}])

	// -------------------------------------------------------
	// -- create a blank html element that won't inherit any styles
	// -------------------------------------------------------
	.directive('mxDiv', [function(){
		return {
			link : function(){ }
		}
	}])

	// -------------------------------------------------------
	// -- Takes the value supplied by mxValue
	// -------------------------------------------------------
	.directive('mxValue', ['$parse', function($parse){
		return {
	    link: function (scope, element, attrs) {
	      if (attrs.ngModel && attrs.value) {
	        $parse(attrs.ngModel).assign(scope, attrs.value);
	      }else{
		    	if (attrs.ngModel && attrs.mxValue) {
		        $parse(attrs.ngModel).assign(scope, attrs.mxValue);
		      }
		    }
	    }
		}
	}])

	// -------------------------------------------------------
	// -- Takes the value supplied by mxValue
	// -------------------------------------------------------
	.directive('mxLazySrc', ['$parse', function($parse){
		return {
	    link: function (scope, element, attrs) {
		    $(element).src = attrs.mxLazySrc
	    }
		}
	}])


	// -------------------------------------------------------
	// Takes a config object to initialize the owl carousel
	// -------------------------------------------------------
	.directive('mxOwl', [function(){
		return {
			controller : ['$scope', '$element', '$attrs', function($scope, $element, $attrs){
				this.config = $scope.carousel_config;
				this.element = $element;
			}]
		}
	}])
	// -------------------------------------------------------
	// Looks at the repeat items
	// to see when the last one has rendered then 
	// initialize the owl carousel from the config and the element in the parent 
	// -------------------------------------------------------
	.directive('mxOwlItem', [function(){
		return {
			require : '^mxOwl',
			link : function(scope, element, attrs , mxOwl){
				if(scope.$last){
					$(mxOwl.element).owlCarousel(mxOwl.config)
				}
			}
		}
	}])
	// -------------------------------------------------------
	// -- Moves the modal ... or anything to just before the body tag
	// -------------------------------------------------------
	.directive('mxMoveModal', [function(){
		return {
			link : function($scope, element , attrs){
				$(element).appendTo('body');
			}
		}
	}])
	
	// -------------------------------------------------------
	// -- Moves the modal ... or anything to just before the body tag
	// -------------------------------------------------------
	.directive('mxPrint', [function(){
		return {
			link : function($scope, element , attrs){
				$(element).css('cursor', 'pointer');
				$(element).on('click', function(){
					window.print();
				})
			}
		}
	}])
	
	// -------------------------------------------------------
	// -- Jump to a category page
	/**
	 * 
		$categories = get_categories();
		foreach( $categories as $cat ){ $cat->link = get_term_link( $cat ); }

	 * 				
	 * 	<div class="styled-select" 
			mx-category-jump-list 
			categories='<?= json_encode_attr( $categories );?>'  
			category='<?= json_encode_attr( $obj  );?>'
			formClass="form-control"></div>
	 */
	// -------------------------------------------------------
	.directive('mxCategoryJumpList', [function(){
		return {
			scope : { 
				categories : '=categories'  , 
				category : '=category' , 
				blogUrl :"@blogUrl" , 
				type :"@type" , 
				formClass:"@formClass",
				taxLabel:"@taxLabel"
			},
			link : function( $scope, $element, $attrs  ){ }, // link
			template : function (  ){ 
				return '\
				<button type="button"\
					ng-if="$ctrl.type == \'buttons\'"\
					class="btn dropdown-toggle"\
					data-toggle="dropdown"\
					aria-haspopup="true"\
					aria-expanded="false">\
					<span >{{$ctrl.category ? $ctrl.category.name : $ctrl.label}} </span>\
				</button>\
				<div class="dropdown-menu ">\
					<a class="dropdown-item "\
						ng-repeat="cat in $ctrl.categories"\
						href="{{cat.link}}">\
						<span class = \'term__label\'>{{cat.name}}</span>\
						<span class = \'term__label count\'>{{cat.count}}</span>\
					</a>\
					</div>\
				</div>\
				<select\
					ng-if="$ctrl.type == \'select\'"\
					ng-class="$ctrl.formClass"\
					ng-options="o as o.name for o in $ctrl.categories"\
					ng-model="$ctrl.category"\
					ng-change="$ctrl.jump( cat.link )">\
					<option value ="" selected="selected">Category</option>\
				</select>';
			}, 
			// template 
			controller : function($scope, $http, $timeout, $attrs ){
				var $ctrl = $scope.$ctrl = this;
				$ctrl.$onInit = function(){
					$ctrl.blogUrl = typeof( $scope.blogUrl ) !== 'undefined' ? $scope.blogUrl : '/blog/';
					$ctrl.label = typeof( $scope.label ) !== 'undefined' ? $scope.label : 'Category';
					$ctrl.type = typeof( $scope.type !== 'undefined' ) ? $scope.type : 'select';
					$ctrl.formClass = $scope.formClass;
					$ctrl.categories = $scope.categories;
					$ctrl.category = $ctrl.categories.find(  function( $cat ){
						 return $scope.category.term_id == $cat.term_id;
					})
					

					
					
				}
				$ctrl.jump = function(){
					if( $ctrl.category == null ) window.location.href = $ctrl.blogUrl
					window.location.href = $ctrl.category.link;
				}

			}
		}
	}])	
	
	
	
	// -------------------------------------------------------
	// -- load more posts 
	/** 
	 * Example Usage: 
		global $wp_query; 
		$page_params = array(
			'query' => json_encode( $wp_query->query_vars ), 
			'current_page' => get_query_var( 'paged' ) ? get_query_var('paged') : 1,
			'max_page' => $wp_query->max_num_pages,
			'view' => $view
		);
		<?php
			// don't display the button if there are not enough posts
			if (  $wp_query->max_num_pages > 1 ){?> 
		<mx-load-more container="#posts-container" params='<?= json_encode_attr( $page_params );?>'></mx-load-more>
		<?php } ?>
		<div id = 'posts-container'>--posts will get loaded into here ----</div>
	**/

	// -------------------------------------------------------
	.directive( 'mxLoadMore', ['$http', '$timeout', function( $http ){
		return {
			scope : { params :"=params" , container :"@container" , btnClass : "@btnClass" , btnLabel : "@btnLabel" , partial : "@partial"},
			template : function( ){
					return '<button type = "button" class ="{{ $ctrl.btn_class}}"\
								ng-click="$ctrl.loadMore()" ng-hide="$ctrl.params.current_page == $ctrl.params.max_page">\
								<span class = "fa fa-spin fa-refresh" ng-show="$ctrl.loading"></span>\
								{{ $ctrl.btn_label}}\
							</button>';
			},
			controller : function( $scope ){

				var $ctrl = $scope.$ctrl = this;
				
				$ctrl.$onInit = function(){
					$ctrl.params = $scope.params;
					$ctrl.container = $scope.container;
					$ctrl.loading = false;
					$ctrl.btn_class = typeof( $scope.btnClass ) !== 'undefined' ? $scope.btnClass : 'btn btn-default';
					$ctrl.btn_label = typeof( $scope.btnLabel ) !== 'undefined' ? $scope.btnLabel : 'Load More';
					$ctrl.partial = typeof( $scope.partial ) !== 'undefined' ? $scope.partial : 'articles/search/search-result';
					
				}
				
				$ctrl.loadMore = function(){
					$ctrl.loading = true;
					$http.post( '/?blog__load_more' , { params : $ctrl.params , partial : $ctrl.partial } ).then( function( response ){
						$ctrl.loading = false;
						if( response.data.count > 0 ){
							if( $('#loaded-more').length > 0 ) $('#loaded-more').remove();
							$('html,body').animate({    
								scrollTop: $('#loaded-more').offset().top
							}, 500);
							$($ctrl.container ).append( '<span id="loaded-more"></span>' );
							$($ctrl.container ).append( response.data.content );
							$ctrl.params.current_page ++;
							document.dispatchEvent(new CustomEvent('mxLoadMore.loaded' , {detail : $ctrl.params  } ) );
						}
					})
				}
				

			}
		}
		
	}]);
	
	
	


;// end of directives